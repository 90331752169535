import React, { FormEvent, useState } from "react";
import axios from "axios";
import {useQueryClient, QueryClient} from "@tanstack/react-query";
import {Alert, Box, Container, Divider, Typography} from "@mui/material";
// import axios from "axios";
import {Navigate} from "react-router-dom";
import {useAuth} from "../../auth/auth.hooks";
import {AssetGroupDetails, DropDownItem,} from "../assetGroups.types";
import {useAssetDetails, useProductDetails, useVendorDetails} from "../assetGroups.hooks";
import {CancelButton, EditButton, InfoFieldPair, InfoObjectDropdownPair, UploadStatusMessage} from "../../viewPageCommonElements/viewPage.component";
// import {GetString} from "../../../../localization";
import {Banner} from "../../banner/banner.component";
import { GetString } from "../../../localization";


type UrlParams = {
    group_name: string;
    group_id: string;
    object_id: string;
    hostname: string;
    group_path: string;
}

export const withHooksHOC = (Component: React.ElementType) => function useHooks(): JSX.Element {
    const {accessToken, config} = useAuth();
    const queryClient = useQueryClient();

    const {pathname} = window.location;
    const parts = pathname.split("/");
    const groupParams = parts[2].split("+");

    const params: UrlParams = {
        group_name: groupParams[0],
        group_id: groupParams[1],
        object_id: parts[3],
        hostname: "",
        group_path: parts[2]
    }

    return <Component accessToken={accessToken} config={config} queryClient={queryClient} params={params}/>;
}

interface ShowAssetProps {
    groupId: string;
    assetId: string;
}

interface InfoBoxProps {
    name: string;
    data: AssetGroupDetails;
    content: string[];
    products: DropDownItem[];
    vendors: DropDownItem[];
    vendorSelected: boolean;
    onVendorChange: (vendor: DropDownItem) => void;
}

interface ViewAssetProps {
    editing: boolean;
    updating: boolean;
    updateStatus: string;
    dataLoaded: boolean;
    vendorsLoaded: boolean;
    defaultData?: AssetGroupDetails;
    currentVendor?: DropDownItem;
    currentProduct?: DropDownItem;
}

interface IHooksHOCProps {
    accessToken: string;
    queryClient: QueryClient;
    config: Record<string, unknown>;
    params: UrlParams
}

const detailsInfoContents: string[] = [
    'product_name',
    'description',
    'vendor_name',
    'version',
    'type',
]


class ViewAssetDetails extends React.Component<any, ViewAssetProps> {
    static groupId = "";

    static addReturn = {
        "group_id": "",
        "object_id": "",
    };

    static FormatFieldData(data: number | string | boolean): string {
        return data as string;
    }

    constructor(props: IHooksHOCProps) {
        super(props);
        this.state = {
            editing: false,
            updating: false,
            updateStatus: "none",
            dataLoaded: false,
            vendorsLoaded: false,
        }
        this.InfoBox = this.InfoBox.bind(this);
        this.ShowDetails = this.ShowDetails.bind(this);
        this.EditFormSubmit = this.EditFormSubmit.bind(this);
        this.PostUpdatedData = this.PostUpdatedData.bind(this);
    }

    componentDidMount(): void {
        const {params} = this.props;

        if (params.object_id === "add") {
            this.setState({
                editing: true
            })
        }
    }

    InfoBox = ({name, data, content, products, vendors, vendorSelected, onVendorChange}: InfoBoxProps): JSX.Element => {
        const {FormatFieldData} = ViewAssetDetails;
        const {updating} = this.state;
        const {editing} = this.state;

        return (
            <Box className="viewAssetBox" style={{float: "left", width: "100%"}}>
                <Typography style={{color: "#ff7321", fontWeight: "bold", fontSize: "larger"}}>
                    {name}
                </Typography>
                <Divider sx={{mt: 0.5, mb: 3}}/>
                    <Box style={{float: "left", width: "30%"}}>
                        <InfoObjectDropdownPair
                            title={GetString(content[2])}
                            name={content[2]}
                            data={FormatFieldData(data[content[2]])}
                            dropdownOptions={vendors}
                            updating={updating}
                            editing={editing}
                            loading={vendors.length === 0}
                            onChangeCallback={onVendorChange}/>
                        <InfoObjectDropdownPair
                            title={GetString(content[0])}
                            name={content[0]}
                            data={FormatFieldData(data[content[0]])}
                            dropdownOptions={products}
                            updating={updating}
                            editing={editing}
                            loading={vendorSelected && vendors.length !== 0 && products.length === 0}
                            disabled={!vendorSelected || vendors.length === 0}
                            onChangeCallback={(product) =>
                                {
                                    this.setState({
                                        currentProduct: product
                                    });
                                }
                            }/>
                        <InfoFieldPair title={GetString(content[1])} name={content[1]} data={FormatFieldData(data[content[1]])} updating={updating} editing={editing}/>
                        <InfoFieldPair title={GetString(content[3])} name={content[3]} data={FormatFieldData(data[content[3]])} updating={updating} editing={editing}/>
                    </Box>
                    <Box style={{float: "left", width: "30%", flexGrow: 1}} sx={{ml: 3}}>
                        <InfoFieldPair title={GetString(content[4])} name={content[4]} data={FormatFieldData(data[content[4]])} updating={updating} editing={editing}/>
                    </Box>
            </Box>
        );
    }

    PostUpdatedData = (assetData: AssetGroupDetails, postType: string): void => {
        const {queryClient} = this.props;
        const {config} = this.props;
        const {accessToken} = this.props;
        const {params} = this.props;

        this.setState({
            updateStatus: params.object_id === "add" ? "adding" : "updating",
            defaultData: assetData
        })

        const conf = {
            params: {
                group_id: params.group_id,
                asset_id: params.object_id,
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        }

        axios.post(`${config.REACT_APP_API_BASE_URL}/${config.REACT_APP_ENVIRONMENT}/otam/asset_groups/assets/asset_group/${postType}`, {
            version: assetData.version,
            vendor_name: assetData.vendor_name,
            product_name: assetData.product_name,
            description: assetData.description,
            ...(assetData.latest_patch !== undefined ? {latest_patch: assetData.latest_patch} : {}),
            ...(assetData.outage !== undefined ? {outage: assetData.outage} : {}),
            type: assetData.type,
        }, conf)
            .then((response) => {
                if(response.status === 200) {
                    // Backend data has been updated, so local cache is no longer valid
                    queryClient.invalidateQueries(['asset_scan_item', { 'id': params.object_id }], { exact: true })
                }

                console.log(response.data);

                ViewAssetDetails.addReturn.object_id = response.data.asset_id;
                ViewAssetDetails.addReturn.group_id = params.group_id;

                this.setState({
                    editing: false,
                    updating: false,
                    updateStatus: params.object_id === "add" ? "added" : "success"
                })
            }).catch(() => {
            this.setState({
                editing: false,
                updating: false,
                updateStatus: "failed"
            })
        })
    }

    EditFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
        const {editing} = this.state;
        const {currentVendor} = this.state;
        const {currentProduct} = this.state;
        const {defaultData} = this.state
        const {params} = this.props;

        e.preventDefault();

        if(!editing) {
            this.setState({
                editing: true
            });
        }

        if(editing) {
            this.setState({
                updating: true,
                updateStatus: params.object_id === "add" ? "adding" : "updating"
            })

            const form = document.getElementById("editForm") as HTMLFormElement;
            const data = new FormData(form);
            data.set("object_id", params.object_id);

            const defaultVendor = defaultData ? defaultData.vendor_name : "";
            data.append("vendor_name", currentVendor ? currentVendor.name : defaultVendor);
            const defaultProduct = defaultData ? defaultData.product_name : "";
            data.append("product_name", currentProduct ? currentProduct.name : defaultProduct);
            const object = {} as AssetGroupDetails;

            data.forEach((value, key)=> {
                object[key] = value as string;
            });

            if(params.object_id === "add") {
                this.PostUpdatedData(object, "add")
            }
            else {
                this.PostUpdatedData(object, "edit")
            }

        }
    }

    ShowDetails = ({groupId, assetId}: ShowAssetProps): JSX.Element => {
        if(groupId === "") {
            return <Alert severity="error" sx={{ mb: 2 }}>Asset group ID was not provided</Alert>
        }
        if(assetId === "") {
            return <Alert severity="error" sx={{ mb: 2 }}>Asset ID was not provided</Alert>
        }

        const {InfoBox} = this;
        const {dataLoaded} = this.state;
        const {vendorsLoaded} = this.state;
        const {defaultData} = this.state;

        const vendorsData = useVendorDetails();
        const [vendorId, setVendorId] = useState(-1);
        const vendors = vendorsData.isSuccess && vendorsData.data ? vendorsData.data : [];
        const productsData = useProductDetails(vendorId);
        const products = productsData.isSuccess && productsData.data ? productsData.data : [];

        if(assetId === "add") {
            const newAsset: AssetGroupDetails = {
                object_id: "-1",
                product_name: "",
                description: "",
                vendor_name: "",
                version: "",
                type: "",
                latest_patch: "",
                outage: "",
                isNew: true,
            };

            if(!dataLoaded) {
                this.setState({
                    dataLoaded: true,
                    defaultData: newAsset,
                });
            }

            return (
                <Box>
                    <Box className="viewAssetContainer">
                        <InfoBox
                                name="Asset Information"
                                data={newAsset}
                                content={detailsInfoContents}
                                products={products}
                                vendors={vendors}
                                vendorSelected={vendorId !== -1}
                                onVendorChange={(vendor) =>
                                    {
                                        this.setState({
                                            currentVendor: vendor
                                        });
                                        setVendorId(vendor.id)
                                    }
                                }
                            />
                    </Box>
                </Box>
            );
        }

        const gId = groupId;
        const aId = Number(assetId);
        const assetData = useAssetDetails(gId, aId);

        if(assetData.isSuccess && !dataLoaded) {
            this.setState({
                dataLoaded: true,
                defaultData: assetData.data
            });
        }

        if(vendorsData.isSuccess && !vendorsLoaded && dataLoaded && assetData.data) {
            this.setState({
                vendorsLoaded: true
            });
            const index = vendorsData.data.findIndex(vendor => vendor.name === assetData.data.vendor_name);
            if (index !== -1) {
                setVendorId(vendorsData.data[index].id)
            }
        }

        return (
            <Box>
                {assetData.isLoading && <Alert severity="info" sx={{ mb: 2 }}>Loading...</Alert>}
                {assetData.isError && (
                    <Alert severity="error" sx={{ mb: 2 }}>Asset data could not be loaded</Alert>
                )}
                {defaultData && (
                    <Box>
                        <Box className="viewAssetContainer">
                            <InfoBox
                                name="Asset Information"
                                data={defaultData} content={detailsInfoContents}
                                products={products} vendors={vendors}
                                vendorSelected={vendorId !== -1}
                                onVendorChange={(vendor) =>
                                    {
                                        this.setState({
                                            currentVendor: vendor
                                        });
                                        setVendorId(vendor.id)
                                    }
                                }
                            />
                            {/* <AssetInfoBox
                            name="Installation Details"
                            data={assetData.data[0]} content={assetInfoContents}
                        /> */}
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }

    render(): JSX.Element {
        const {ShowDetails} = this;
        const {editing} = this.state;
        const {updating} = this.state;
        const {updateStatus} = this.state;
        const {dataLoaded} = this.state;
        const {params} = this.props;

        const {pathname} = window.location;
        const parts = pathname.split("/");
        const groupParams = parts[2].split("+")

        // eslint-disable-next-line prefer-destructuring
        params.group_id = groupParams[1];
        // eslint-disable-next-line prefer-destructuring
        params.object_id = parts[3];

        console.log(params);

        return (
            <Box>
                {params.object_id === "add" && updateStatus === "added" &&
                    <Navigate
                        to={`/asset_groups/${params.group_path}/${ViewAssetDetails.addReturn.object_id}`}
                        replace
                    />
                }
                <Banner firstLine="Asset Data" secondLine={`View Asset ID ${params.group_id}`}/>
                <Container maxWidth="xl">
                    <form id="editForm" onSubmit={this.EditFormSubmit}>
                        <Box style={{ float: "right" }}>
                            {params.object_id !== "add" && (
                                <CancelButton
                                    editing={editing}
                                    updating={updating}
                                    onClick={() => {
                                        this.setState({
                                            editing: false
                                        })
                                    }}
                                />
                            )}
                            <EditButton requiredRoles={["otam_admin", "otam_psirt"]} editing={editing} updating={updating} enabled={dataLoaded}/>
                        </Box>
                        <Box className="clearBoth">
                            <UploadStatusMessage status={updateStatus}/>
                        </Box>
                        <Box>
                            <ShowDetails groupId={params.group_id} assetId={params.object_id}/>
                        </Box>
                    </form>
                </Container>

            </Box>
        );
    }
}

export default withHooksHOC(ViewAssetDetails);
